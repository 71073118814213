@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

@import "~@nebular/theme/styles/globals";

@import "./bootstrap";

@import "~swiper/swiper-bundle.min";
@import "~cropperjs/dist/cropper.min.css";

@import "./pace.theme";

@import "./layout";
@import "./overrides";
@import "./variables";

@include nb-install() {
  @include nb-theme-global();

  @include ngx-layout();
  @include ngx-pace-theme();

  @include nb-overrides();
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

table {
  overflow: auto;
  width: 100%;
  white-space: nowrap;
}

.mat-row {
  height: auto !important;
}
.mat-cell,
.mat-header-cell {
  padding: 10px 10px 10px 10px !important;
}

.mat-slide-toggle-label {
  display: inline-flex !important;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

.spin-circle {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite !important;
  border-top-color: var(--primary) !important ;
  border-bottom-color: var(--primary) !important ;
  border-left-color: var(--primary) !important ;
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

label {
  display: block;
  span {
    color: #70b3d8;
  }
}

mat-radio-group {
  display: inline-grid;
}
button {
  text-transform: capitalize !important;
}

.mat-sort-header-arrow {
  display: none !important;
}

.nb-theme-default nb-card-header {
  border-bottom: 0 !important;
}

.table-details {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &-img {
    img {
      object-fit: cover;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
    nb-user.size-medium .initials {
      font-size: 0.9rem;
    }
    nb-user.size-medium .user-picture {
      height: 35px;
      width: 35px;
    }
    .user-container {
      display: unset !important;
    }
  }
  &-title {
    margin-inline-start: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  &:hover {
    color: var(--primary) !important;
    text-decoration: underline;
  }
}

.details {
  cursor: pointer;
  margin-inline-start: 0.5rem;
}

.verified {
  margin-inline-start: 0.3rem;
  margin-top: -1px;
  img {
    width: 15px;
    height: 15px;
  }
}

#textarea {
  height: 154px !important;
}

a {
  color: var(--primary) !important;
}
.mat-radio-inner-circle {
  background-color: var(--primary) !important;
}
.mat-radio-button {
  margin-bottom: 5px;
}

.table-link {
  cursor: pointer !important;

  a {
    color: var(--title-color) !important;
  }
}

.navigation {
  display: none;
}

.btn-icon {
  border: 1px solid var(--border-color) !important;
  margin-inline-end: 12px !important;
  color: #aeb0b5;
  width: 48px !important;
  height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }
  &-dark {
    color: var(--primary);
    border-color: var(--primary) !important;
  }
  &-white {
    background-color: var(--white) !important;
    color: var(--primary);
    &:hover {
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;
    }
  }
  mat-icon {
    margin-inline-end: 0 !important;
    font-size: 1.4rem !important;
  }
}
.tooltip-primary {
  background: #123945;
  color: #fff;
  font-size: 12px;
}

@import "./dark";
