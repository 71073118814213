.table {
  border-radius: 5px;
  padding: 2px 0;
  margin-top: 1rem;
}
.table > :not(caption) > * > * {
  border: 1px solid var(--grey);
}

td.mat-cell {
  border: none;
}
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border: none !important;
}

.mat-sort-header-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--title-color);
}

td.mat-cell {
  overflow: hidden;
  &:has(.table-details) {
    @include media-breakpoint-up(lg) {
      max-width: 115px;
    }
  }
  &:has(.table-details-sard) {
    @include media-breakpoint-up(lg) {
      max-width: 171px;
    }
  }
}
