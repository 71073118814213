@import "./themes";
@import "./inputs";
@import "./buttons";
@import "./buttons";
@import "./table";

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }
}

//spinner
nb-spinner.size-giant {
  border: none !important;
}

//icons
.material-icons {
  font-size: 18px;
  line-height: 1.3;
  margin-right: 3px;
}

nb-icon {
  cursor: pointer;
  font-size: 1.6rem !important;
  color: var(--primary) !important;
}

//side menu {
nb-menu ul.menu-items {
  &:first-child {
    padding-top: 20px !important;
    @include media-breakpoint-down(sm) {
      padding-top: 0 !important;
      max-height: 80vh;
      overflow-y: auto;
    }
  }
  .menu-item {
    border: none;
  }
  .menu-item a .menu-title {
    font-weight: 600;
    font-size: 16px;
    color: #999da0;
    &:hover {
      color: var(--primary) !important;
    }
  }
}
.active-page {
  border-left: 4px solid var(--primary) !important;
  span {
    color: var(--primary) !important;
  }
}

nb-menu .menu-item .menu-item:first-child {
  border-top: 0 !important;
}

//tab
.full-width .tabset {
  justify-content: center !important;
  margin-bottom: 16px !important;
}

nb-tabset .tab-link {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #6a7178 !important;
  text-transform: capitalize !important;
}

nb-tabset .tab.active .tab-link {
  font-weight: 600 !important;
  color: var(--title-color) !important;
}

nb-tabset .tab.active .tab-link::before {
  background-color: var(--primary) !important;
  height: 2px !important;
}

nb-tabset .tab-link::before {
  background-color: var(--grey) !important;
  height: 1px !important;
}

//hr
hr {
  opacity: 1 !important;
  background-color: var(--grey) !important;
}

//accordion
nb-accordion {
  box-shadow: none !important;
}

nb-accordion-item-header {
  border-bottom-color: var(--grey) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

nb-accordion-item-body .item-body {
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  ul {
    margin-top: 10px;
  }
  ul li {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 21px !important;
  }
}

//paginator
.mat-paginator-container {
  justify-content: space-between !important;
}

.mat-paginator-range-actions {
  button {
    &:first-of-type {
      order: 1;
    }
    &:last-of-type {
      order: 3;
    }
  }
  .mat-paginator-range-label {
    order: 2;
  }
}

//actions
.fixed-actions {
  position: fixed;
  bottom: 4%;
  right: 3.8%;
  z-index: 1024;
}

//badge
.status {
  border-radius: 16px;
  padding: 5px 8px;
  max-width: 91px;
  text-align: center;
  font-weight: bold;
  &-block,
  &-cancel {
    color: var(--orange);
    background: rgba(239, 68, 68, 0.08);
  }
  &-active,
  &-done {
    background: rgba(34, 197, 94, 0.1);
    color: #008000;
  }

  &-progress {
    background: rgba(234, 179, 8, 0.1);
    color: #ca8a04;
  }
  &-done {
    color: #16a34a;
  }
}

//sort-menu
.mat-menu-panel {
  min-width: 290px !important;
  max-width: unset !important;
  margin-top: 10px !important;
}

.mat-badge-content,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: var(--primary) !important;
}

.overlay-backdrop {
  cursor: pointer;
}

nb-dialog-container {
  padding: 1rem;
}

.custom-modalbox {
  mat-dialog-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }
}

.swiper-wrapper {
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--primary);
  background-color: var(--white);
  height: 28px;
  border-radius: 20px;
  &:after {
    font-size: 1.1rem;
    position: absolute;
  }
}
.swiper-button-next:after {
  left: 10px;
}

.swiper-button-prev:after {
  right: 10px;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  outline: 1px solid var(--primary);
  outline-color: var(--primary);
}

.cropper-line,
.cropper-point,
nb-toast.status-success {
  background-color: var(--primary) !important;
}

.comment {
  &-content {
    &-avatar {
      .user-container {
        display: unset !important;
      }
    }
  }
}

.card-icon {
  &:has(mat-icon) {
    .mat-icon {
      height: unset;
      width: unset;
    }
    .material-icons {
      margin-right: unset;
      line-height: unset;
      font-size: 30px;
    }
  }
}

nb-spinner {
  z-index: 999 !important;
}

h2 {
  margin-bottom: 0 !important;
}