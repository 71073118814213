[nbButton].appearance-filled.status-primary,
[nbButton].appearance-filled.status-basic,
[nbButton].appearance-filled.status-info,
[nbButton].appearance-filled.status-danger {
  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
  border-radius: 80px;
  min-width: 151px;
  width: auto;
  height: 48px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  padding: 0 !important;
  padding-right: 25px !important;
  padding-left: 20px !important;
  @include media-breakpoint-down(md) {
    min-width: 100px;
  }
}

[nbButton].appearance-filled.status-primary,
[nbButton].appearance-hero.status-success,
[nbButton].appearance-outline.status-primary {
  background: var(--primary) !important;
  border: 1px solid var(--border-color) !important;
  color: var(--white) !important;
  nb-icon {
    color: var(--white) !important;
  }
  &:hover {
    background: var(--white) !important;
    color: var(--primary) !important;
    border-color: var(--primary) !important;
    nb-icon {
      color: var(--primary) !important;
    }
  }
}

[nbButton].appearance-filled.status-secondary {
  &:hover {
    background: var(--primary) !important;
    color: var(--white) !important;
  }
}

[nbButton].appearance-filled.status-basic,
[nbButton].appearance-filled.status-info {
  background: var(--white) !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  &:hover {
    background: var(--primary) !important;
    color: var(--white) !important;
  }
}
[nbButton].appearance-filled.status-info {
  border-color: var(--border-color) !important;
}
[nbButton].appearance-filled.status-danger {
  background: var(--white) !important;
  border: 1px solid var(--orange) !important;
  color: var(--orange) !important;
  &:hover {
    background: #ef4444a3 !important;
    color: var(--white) !important;
  }
}
