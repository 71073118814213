:root {
  --primary: #123945;
  --white: #ffffff;
  --orange: #ef4444;
  --grey: #ced4da;
  --grey2: #4f575e;
  --blue: #385898;
  --red: #ff0000;
  --red2: #477f52;
  --title-color: #272b30;
  --border-color: #ebeef2;
}
