[nbInput].size-medium,
nb-select.size-medium {
  border-radius: 1.5rem !important;
  background: var(--white) !important;
  border: 1px solid var(--red2) !important;
  border-radius: 4px !important;
  height: 48px !important;
  margin-top: 10px !important;
  &:focus {
    box-shadow: 0 0 10px #477f5279 !important;
  }
}

nb-select.size-medium {
  display: flex;
  button {
    background: transparent;
    padding: 0;
    border: none;
    margin-bottom: 0;
  }
}

nb-checkbox.status-basic .custom-checkbox.checked nb-icon {
  color: var(--white) !important;
}

nb-radio.status-basic .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--primary) !important;
}

nb-radio.status-basic .native-input:enabled:checked + .outer-circle {
  border-color: var(--primary) !important;
}

nb-radio:hover {
  &.status-basic .native-input:enabled + .outer-circle {
    border-color: var(--primary) !important;
  }
}

.mat-error {
  font-size: 11px;
  display: block;
}

.iti {
  display: block !important;
}
.iti__country-list {
  bottom: 3rem;
}
.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  left: auto !important;
  right: 0 !important;
  top: 9px !important;
}
.iti__selected-flag {
  padding: 0 18px 0 20px !important;
}
.iti__country-list {
  right: 0;
  text-align: right !important;
}

[nbButton].size-medium.icon-start.icon-end.appearance-ghost {
  padding: 0.5rem !important;
}
