.nb-theme-dark {
  nb-icon {
    color: var(--white) !important;
  }
  .search-input,
  .search-btn {
    background: transparent !important;
  }
  .sidebar-slider,
  [nbInput].status-basic {
    background-color: var(--input-basic-background-color) !important;
  }
  [nbButton].appearance-filled.size-large {
    background-color: transparent;
    color: var(--white);
    border-color: var(--white);
  }
  .btn-icon-dark {
    color: var(--white);
    border-color: var(--white) !important;
  }
  .mat-table {
    background-color: transparent;
    th.mat-header-cell,
    td.mat-cell {
      color: var(--white);
    }
  }
  .mat-paginator,
  [nbButton].appearance-filled.status-basic,
  [nbButton].appearance-filled.status-info {
    background-color: transparent !important;
    color: var(--white) !important;
  }
  [nbButton].appearance-filled.status-basic,
  [nbButton].appearance-filled.status-info {
    border-color: var(--white) !important;
    &:hover {
      background-color: var(--primary) !important;
    }
  }
  [nbButton].appearance-filled.status-primary {
    &:hover {
      background-color: transparent !important;
      color: var(--white) !important;
      border-color: var(--white) !important;
    }
  }
  nb-menu ul.menu-items {
    .menu-item a .menu-title {
      &:hover {
        color: var(--white) !important;
      }
    }
  }
  .active-page {
    border-color: var(--white) !important;
    span {
      color: var(--white) !important;
    }
  }
  ::-webkit-scrollbar-track,
  .mat-dialog-container,
  .card,
  .sard-card,
  .mat-menu-panel {
    background-color: var(--header-background-color);
    color: var(--white);
  }
  a,
  nb-tabset .tab.active .tab-link,
  .account-info-item p,
  .certificate-title {
    color: unset !important;
  }
  button.mat-menu-item,
  .session p,
  .profile-name,
  .sidebar-slider nb-icon,
  .toggle nb-icon,
  .sard-card nb-icon,
  .card .mat-icon,
  .tax p,
  .status,
  nb-menu ul.menu-items .menu-item nb-icon,
  nb-toast.status-success nb-icon {
    color: var(--white) !important;
  }
  .account-info-item-socials a div {
    color: var(--primary) !important;
  }
  .day nb-card {
    nb-card-body {
      background: var(--layout-background-color) !important;
      p {
        color: var(--white) !important;
      }
    }
  }
  .iti__country-list,
  .status {
    background: var(--layout-background-color) !important;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  nb-auth {
    nb-icon {
      color: var(--white) !important;
    }
    nb-card-header {
      display: none !important;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    border: 1px solid var(--red2) !important;
    -webkit-text-fill-color: var(--white) !important;
    -webkit-box-shadow: 0 0 0px 1000px var(--layout-background-color) inset !important;
  }

  .notification {
    border-color: transparent !important;
    &-unseen {
      background-color: var(--layout-background-color) !important;
    }
  }

  .table-details:hover {
    color: var(--white) !important;
  }
}
